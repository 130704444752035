import React from "react";

class Toolbox extends React.Component {
  constructor(props) {
    super(props);
    let newState = {}
    this.availableTools = ["pen", "eraser"];

    if (props.toolsEnabled) {
      newState.toolsEnabled = [];
      props.toolsEnabled.forEach((item) => {
        if (this.availableTools.indexOf(item) > -1) {
          newState.toolsEnabled.push(item)
        }
      })
    } else {
      newState.toolsEnabled = this.availableTools;
    }

    if (props.toolSelected) {
      newState.toolSelected = props.toolSelected;
    } else {
      newState.toolSelected = this.availableTools[0]
    }

    if (props.startPosX) {
      newState.posX = props.startPosX;
    } else {
      newState.posX = 50;
    }

    if (props.startPosY) {
      newState.posY = props.startPosY;
    } else {
      newState.posY = 50;
    }

    this.state = newState;
    this.clickHandler = this.clickHandler.bind(this);
    this.handleDragStart = this.handleDragStart.bind(this);
    this.mouseDragMove = this.mouseDragMove.bind(this);
    this.handleDrop = this.handleDrop.bind(this);
  }

  clickHandler(tool) {
    console.log(tool);
    this.setState({toolSelected: tool})
  }

  handleDragStart(e) {
    console.log("Drag Start");
    const dragElement = e.target;
    e.dataTransfer.effectAllowed = "move";
    e.dataTransfer.setData("text/html", dragElement.outerHTML);
    this.setState({attachedToCanvas: false, dragStartX: e.clientX, dragStartY: e.clientY});
    this.props.drawingEnabler(false);
  }

  handleDrop() {
    console.log("Drop");
    let newState = {};
    newState.attachedToCanvas = true;
    console.log(newState);
    this.setState(newState);
    this.props.drawingEnabler(true);
  }

  mouseDragMove(e) {
    console.log("Move");
    let newState = {};
    // calculate the new position
    // 50 - 51 = 1
    const posXDelta = this.state.dragStartX - e.clientX;
    // 50 - 50 = 0
    const posYDelta = this.state.dragStartY - e.clientY;

    // with each move we also want to update the start X and Y
    // 51
    newState.dragStartX = e.clientX;
    // 50
    newState.dragStartY = e.clientY;

    // set the element's new position:
    // 50 - 0
    newState.posY = this.state.posY - posYDelta;
    // 49
    newState.posX = this.state.posX - posXDelta;
    console.log(newState);
    this.setState(newState);
  }

  handleDragOver(e) {
    e.stopPropagation();
    e.preventDefault();
  }

  componentDidMount() {
    this.props.toolChangeHandler(this.state.toolSelected);
  }

  render() {
    console.log("top: " + this.state.posY, "left: " + this.state.posX)
    return (
      <div
        className={"toolbox"}
        onDragStart={this.handleDragStart}
        onDrag={this.mouseDragMove}
        onDrop={this.handleDrop}
        onDragOver={this.handleDragOver}
        draggable={true}
        style={{top: this.state.posY, left: this.state.posX}}
      >
        <span className={"drag-handle"}>III</span>
        {
          this.state.toolsEnabled.map((tool, i) => {
            return <div
              key={i}
              className={
                "toolbox-tool " +
								"tool-" + tool +
								(this.state.toolSelected === tool ? " tool-active" : "")
              }
              onClick={(e) => {
                this.clickHandler(tool, e);
                this.props.toolChangeHandler(tool, e);
              }}
            >{tool}</div>
          })
        }
      </div>
    )
  }
}

export default Toolbox;
