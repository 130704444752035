import React from "react";

class GridItem extends React.Component {
  constructor(props) {
    super(props);
    this.state = {selected: props.selected, on: props.on};
    this.handleMouseEnter = this.handleMouseEnter.bind(this);
    this.handleMouseLeave = this.handleMouseLeave.bind(this);
    this.handleAction = this.handleAction.bind(this);
  }

  handleMouseEnter() {
    let newState = {hovered: true};
    if (this.props.appendMode) {
      this.handleAction();
    }
    this.setState(newState);
  }

  handleMouseLeave() {
    this.setState({hovered: false});
  }

  handleAction() {
    let newState = {};
    if (this.props.drawingAllowed) {
      if ("pen" === this.props.currentTool) {
        newState.on = true;
      } else if ("eraser" === this.props.currentTool) {
        newState.on = false;
      }
      this.setState(newState);
    }
  }

  // if (
  //   typeof props.pingId === "number" &&
  //   props.pingId !== props.index &&
  //   props.selected &&
  //   !props.appendMode
  // ) {
  //   props.selectHandler(props.index, false);
  // }

  render() {
    return (
      <div
        className={
          "gridItem gridItem-" + this.props.index +
					(this.state.on ? " itemOn" : "") +
					(this.state.selected ? " itemSelected" : "") +
					(this.state.hovered ? " itemHovered" : "")
        }
        tabIndex={this.props.index}
        role={"checkbox"}
        aria-checked={this.state.selected ? "true" : "false"}
        onMouseEnter={this.handleMouseEnter}
        onMouseLeave={this.handleMouseLeave}
        onMouseDown={this.handleAction}
      />
    );
  }
}

export default GridItem;
