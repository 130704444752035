import React from "react";
import "./App.css";
import GridArea from "./Components/GridArea";

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <h1>justin maurer dot dev</h1>
        <h2>Internet plumber.</h2>
      </header>
      <GridArea pixels={20} />
    </div>
  );
}

export default App;
