import React, {useState} from "react";
import {getGridUnits} from "../util/gridTools";
import GridItem from "./GridItem";
import Toolbox from "./Toolbox";

function GridArea(props) {
  const gridUnitsCount = getGridUnits(props.pixels);
  const gridUnits = [...Array(gridUnitsCount).keys()];
  let mapInit = gridUnits.map((item, i) => {
    return {index: i, on: false, selected: false};
  });

  const [unitMap] = useState(mapInit);

  const [pingId, setPingId] = useState(false);

  const [appendMode, setAppendMode] = useState(false);

  const [drawingAllowed, setDrawingAllowed] = useState(true);

  const [activeTool, setActiveTool] = useState("pen");

  const mouseDownHandler = function (e) {
    console.log(drawingAllowed, e.target)
    if (!e.target.classList.contains("drag-handle")) {
      console.log("ding");
      setAppendMode(true);
    } else {
      console.log("dong");
      setAppendMode(false);
    }
  };

  const mouseUpHandler = function (event) {
    if (!event.shiftKey) {
      setAppendMode(false);
    }
  };

  return (
    <div
      className="gridArea"
      // onKeyDown={keydownHandler}
      // onKeyUp={keyUpHandler}
      onMouseDown={mouseDownHandler}
      onMouseUp={mouseUpHandler}
    >
      <Toolbox
        toolsEnabled={["pen", "eraser"]}
        toolChangeHandler={setActiveTool}
        drawingEnabler={setDrawingAllowed}
      />
      {gridUnits.map((item, i) => {
        return (

          <GridItem
            key={i}
            index={unitMap[i].index}
            currentTool={activeTool}
            appendMode={appendMode}
            pingId={pingId}
            pingHandler={(id) => {
              setPingId(id);
            }}
            // selectHandler={selectHandler}
            // onHandler={onHandler}
            on={unitMap[i].on}
            selected={unitMap[i].selected}
            drawingAllowed={drawingAllowed}
          />
        );
      })}
    </div>
  );
}

export default GridArea;
