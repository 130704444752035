export function calculateAvailableRows(pxheight) {
  const windowHeight = window.innerHeight;
  pxheight = parseInt(pxheight);

  return Math.floor(windowHeight / pxheight) - 5;
}

export function calculateAvailableCols(pxwidth) {
  const windowWidth = window.innerWidth;
  pxwidth = parseInt(pxwidth);

  return Math.floor(windowWidth / pxwidth) - 1;
}

export function getGridUnits(sqpx) {
  const cols = calculateAvailableCols(sqpx);
  const rows = calculateAvailableRows(sqpx);
  return cols * rows;
}
